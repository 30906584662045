/* hedder button */
.hedder-button{
   margin-right: 20px;
}
@media (max-width: 390px) {
  .hedder-button{
    margin-right: 5px;
  }
}

@media (max-width: 412px) {
  .hedder-button{
    margin-right: 5px;
  }
}
@media (max-width: 360px) {
  .hedder-button{
    margin-right: 0;
  }
}

/* home page css */
.logo-pink {
  height: 80px;
  width: 130px;
}

.carousel-image {
  height: 700px;
  width: 100%;
}

.font-color {
  color: #b95c8c;
}

.bg-header {
  background-color: #b95c8c;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
  font-size: 40px;
  letter-spacing: 1px;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
  word-spacing: 1.5px;
}

.padding-1 {
  padding: 12px;
}

.header-button {
  text-decoration: none;
  background-color: transparent;
  border: none;
  color: white;
  letter-spacing: 1px;
}

.header-button:hover {
  color: black;
}

.icon {
  height: 100px;
  width: 100px;
}

@media (max-width: 576px) {
  .logo-pink {
    height: 50px;
    width: 50px;
  }

  .carousel-image {
    height: 400px;
    width: 100%;
  }
}

@media (max-width: 430px) {

  .carousel-image {
    height: 300px;
    width: 100%;
  }

}

@media (max-width: 400px) {
  .carousel-image {
    height: 250px;
    width: 100%;
  }
}

@media (max-width: 375px) {

  .carousel-image {
    height: 200px;
    width: 100%;
  }
}

/* about css */
.box {
  background-color: transparent;
  border: 1px solid #b95c8c;
  height: 400px;
  width: 340px;
  border-top-left-radius: 45px;
  border-bottom-right-radius: 45px;
}

.Architectural {
  font-family: Light 300;
  font-size: 40px;
  line-height: 50px;
  margin-top: 10%;
}

.lineUp {
  animation: 4s anim-lineUp ease-out infinite;
  animation-delay: 0.4s;
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.recent-work-p {
  font-family: "Poppins", sans-serif;
  color: grey;
  font-size: 15px;
}

@media (max-width: 576px) {
  .box {
    background-color: transparent;
    border: 1px solid #b95c8c;
    height: 400px;
    width: 80%;
    border-top-left-radius: 45px;
    border-bottom-right-radius: 45px;
    margin: auto;

  }
}

@media (max-width: 430px) {
  .box {
    background-color: transparent;
    border: 1px solid #b95c8c;
    height: 400px;
    width: 90%;
    border-top-left-radius: 45px;
    border-bottom-right-radius: 45px;
    margin: auto;

  }
}

@media (max-width: 400px) {
  .box {
    background-color: transparent;
    border: 1px solid #b95c8c;
    height: 400px;
    width: 90%;
    border-top-left-radius: 45px;
    border-bottom-right-radius: 45px;
    margin: auto;

  }
}

@media (max-width: 375px) {
  .box {
    background-color: transparent;
    border: 1px solid #b95c8c;
    height: 400px;
    width: 90%;
    border-top-left-radius: 45px;
    border-bottom-right-radius: 45px;
    margin: auto;

  }
}

.product-image {
  height: 250px;
  width: 270px;
  padding: 10px;
  transition: transform .2s;
  border-radius: 5px;
}

.product-image:hover {
  border: 2px solid black;
  transform: scale(1.1);
}

@media (max-width: 576px) {
  .product-image {
    height: 350px;
    width: 480px;
    padding: 10px;
    margin: auto;
  }
}

@media (max-width: 430px) {
  .product-image {
    height: 350px;
    width: 350px;
    padding: 10px;

  }
}

@media (max-width: 400px) {
  .product-image {
    height: 320px;
    width: 320px;
    padding: 10px;

  }
}

@media (max-width: 375px) {
  .product-image {
    height: 280px;
    width: 280px;
    padding: 10px;

  }
}

/* fotter css */
.fotter-color {
  background-color: #b95c8c;
}

.fotter-font1 {
  color: white;
  font-weight: bold;
  margin-top: 20px;
  font-size: 25px;
}

.fotter-font2 {
  color: white;
  font-size: 15px;
  text-decoration: none;
}

.fotter-font3 {
  color: white;
  font-size: 22px;
}

.foter-logo {
  height: 150px;
  width: 232px;
}

/* contact page css */
::placeholder {
  color: black;
  font-family: "Poppins", sans-serif;
  padding-bottom: 10px;
  font-size: 18px;
  color: gray;
}

.border-box {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #b95c8c;
  width: 700px;
}

.button-submit {
  width: 150px;
  height: 40px;
  font-size: 18px;
  background-color: #b95c8c;
  border: none;
  color: white;
  font-family: "Poppins", sans-serif;
}

.button-submit:hover {
  background-color: black;
  color: white;
  border: 1px solid grey;
}

.email-txt {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  color: #b95c8c;
}

.email-txt1 {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  color: rgb(43, 43, 43);
}

.txt11 {
  color: black;
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  padding-top: 4%;
}

@media (max-width: 576px) {
  .border-box {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid rgb(162, 161, 161);
    width: 215px;
  }

  .contact-get-txt {
    font-family: "Poppins", sans-serif;
    font-size: 30px;
  }

  .email-txt3 {
    width: 200px;
  }

  .button-submit {
    width: 150px;
    height: 40px;
    font-size: 18px;
    background-color: grey;
    border: none;
    color: white;
    font-family: "Poppins", sans-serif;
    margin-top: 30px;
  }

  ::placeholder {
    color: black;
    font-family: "Poppins", sans-serif;
    padding-bottom: 10px;

    font-size: 15px;
    color: gray;
  }
}

@media (max-width: 400px) {
  .border-box {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid rgb(162, 161, 161);
    width: 370px;
  }

  .contact-get-txt {
    font-family: Light 300;
    font-size: 30px;
  }

  .email-txt3 {
    width: 200px;
  }

  ::placeholder {
    color: black;
    font-family: "Poppins", sans-serif;
    padding-bottom: 10px;

    font-size: 15px;
    color: gray;
  }
}

@media (max-width: 375px) {
  .border-box {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid rgb(162, 161, 161);
    width: 330px;
  }

  .contact-get-txt {
    font-family: "Poppins", sans-serif;
    font-size: 30px;
  }

  .email-txt3 {
    width: 200px;
  }

  ::placeholder {
    color: black;
    font-family: "Poppins", sans-serif;
    padding-bottom: 10px;
    font-size: 15px;
    color: gray;
  }

  .button-submit {
    width: 150px;
    height: 40px;
    font-size: 18px;
    background-color: #b95c8c;
    border: none;
    color: white;
    font-family: "Poppins", sans-serif;
    margin-top: -20px;
  }

  .button-submit:hover {
    background-color: black;
    color: white;
    border: 1px solid grey;
  }

}